<template>
  <div class="navigation">
    <div class="title">
     <span>数据字典 / </span> <span class="black">学校库</span>
    </div>
    <el-card class="condition_style2">
      <div class="condition_div">
        <el-form :inline="true" class="flex">
          <el-form-item>
            <el-button  type="primary" size="small" @click="add('')">新增</el-button>
          </el-form-item>
          <div >
            <el-form-item>
              <el-input v-model="searchForm.schoolName" placeholder="请输入学校名称" suffix-icon="el-icon-search" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-select placeholder="请选择办学性质" filterable v-model="searchForm.isPublic" clearable>
                <el-option v-for="(item, index) in publicList" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select placeholder="请选择开设年级" filterable v-model="searchForm.schoolLevel" clearable>
                <el-option v-for="(item, index) in classList" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(current-1) * size+ 1}}</template>
        </el-table-column>
        <el-table-column prop="eduCode" label="学校编码" min-width="140" align="center"></el-table-column>
        <el-table-column prop="schoolName" label="学校名称" min-width="180" align="center"></el-table-column>
        <el-table-column prop="address" label="所在地址" min-width="250" align="center">
          <template slot-scope="scope">
              {{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.address}}
          </template>
        </el-table-column>
        <el-table-column prop="isPublic" label="办学性质" min-width="180" align="center">
          <template slot-scope="scope">
             {{scope.row.isPublic==1?"公立":"私立"}}
          </template>
        </el-table-column>
        <el-table-column prop="schoolLevel" label="开设年级" min-width="200" align="center">
          <template slot-scope="scope"> {{Level(scope.row.schoolLevel[0])}} {{Level(scope.row.schoolLevel[1])}} {{Level(scope.row.schoolLevel[2])}} {{Level(scope.row.schoolLevel[3])}}</template>
        </el-table-column>
        <el-table-column prop="scale" label="办学规模" min-width="120" align="center"></el-table-column>
        <el-table-column prop="contacts" label="联系人" min-width="120" align="center"></el-table-column>
        <el-table-column prop="phone" label="联系手机" min-width="120" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="80" align="center">
          <template slot-scope="scope">
            <span class="dot" :class="stateClass(scope.row.status)"></span> {{showState(scope.row.status)}}
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="最后操作时间" min-width="200" align="center"></el-table-column>
        <el-table-column prop="updateBy" label="最后操作人" min-width="100" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="250" align="center">
          <template slot-scope="scope">
            <el-button  class="ge" type="text" size="small" @click="changeState(scope.row,1)">
              {{scope.row.status==1?"启用":"禁用"}}</el-button><span class="ge">|</span>
            <el-button  type="text" size="small" @click="add(scope.row)"> 编辑</el-button><span class="ge">  |</span>
            <el-button  type="text" size="small" @click="changeState(scope.row,false)">删除 </el-button><span class="ge">  |</span>
            <el-button type="text" size="small" @click="reset(scope.row.id)"> 重置管理员密码</el-button>
          </template>
        </el-table-column>
      </el-table>
    <pagination :page="searchForm.current" :size="searchForm.size" :total="total" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>
    <!-- 新增\编辑 -->
    <modal :obj="{
        title: edit ? '编辑机构' : '新增机构',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
      }" @submit="addSubmit">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="学校编码：" prop="eduCode">
          <el-input v-model="addForm.eduCode" placeholder="请输入学校唯一机构编码"></el-input>
        </el-form-item>
        <el-form-item label="学校名称：" prop="schoolName">
          <el-input v-model="addForm.schoolName" placeholder="请输入学校名称"></el-input>
        </el-form-item>
        <el-form-item label="办学性质：" prop="isPublic">
          <el-select placeholder="请选择办学性质" class="w_100" v-model="addForm.isPublic">
            <el-option v-for="(item, index) in publicList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开设年级：" prop="schoolLevel">
          <el-checkbox-group v-model="addForm.schoolLevel">
            <el-checkbox :label="item.id" v-for="(item, index) in classList" @change="$forceUpdate()" :key="index">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="办学规模：" prop="scale">
          <el-input v-model="addForm.scale" placeholder="请输入学校办学规模"></el-input>
        </el-form-item>
        <el-form-item label="所在区域：" prop="area">
          <el-cascader class="w_100" v-model="addForm.area" :options="areaLevelList"
            :props="{ value: 'id', label: 'name'  }" ref="clearCheckedNodes"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址：" prop="address">
          <el-input v-model="addForm.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="定位：" prop="longitudeAndLatitude">
          <el-input v-model.trim="addForm.longitudeAndLatitude" placeholder="请输入经纬度，格式：纬度,经度"></el-input>
        </el-form-item>
      </el-form>
    </modal>
    <!-- 重置密码 -->
    <modal :obj="{
        title: '重置密码',
        dialog: resetDialog,
        dialogText: 'resetDialog',
        form: resetForm,
        ref: 'resetForm',
      }" @submit="resetSubmit">
      <el-form :model="resetForm" label-width="130px" ref="resetForm">
        <el-form-item label="新密码：" prop="password"
          :rules="[{ required: true, message: '请输入新密码' }, {min: 6,max: 12,message: '长度在 6 到 12 个字符'}]">
          <el-input v-model="resetForm.password" placeholder="请输入新密码" type="password" maxlength="12" show-password></el-input>
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "@/components/pagination";
  import modal from "@/components/modal";
  import {
    areaLevel,
    schoolSave,
    getSchoolList,
    schoolInfo,
    schoolUpdate,
    schoolStatus,
    deleteSchool
  } from "@/api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        size:10,//每页条数
        current:1,//当前条数
        total: 0,
        searchForm: {
          name: "",
          type: null,
          status: 0
        },
        resetDialog:false,
        resetForm: {
          password: "",
          id: "",
        },
        tableHeight: this.$util.getHeight(330),
        tableData: [],
        areaLevelList: [],
        classList:[{
          id:"0",
          name:"幼儿园",
        },{
          id:"1",
          name:"小学",
        },{
          id:"2",
          name:"初中",
        },{
          id:"3",
          name:"高中",
        },{
          id:"4",
          name:"大学",
        }],
        publicList:[{
          id:0,
          name:"私立",
        },{
          id:1,
          name:"公立",
        }],
        addDialog: false,
        edit: "",
        addForm: {
          schoolName: "",
          eduCode:"",
          province:"",
          city:"",
          district:"",
          area: [],
          address: "",
          isPublic: "",
          schoolLevel:[],
          lng: "",
          lat: "",
          scale:"",
          longitudeAndLatitude: ""
        },
        rules: {
         eduCode: [{
            required: true,
            message: "请输入学校编码"
          }],
          schoolName: [{
            required: true,
            message: "请输入机构名称"
          }],
          isPublic: [{
            required: true,
            message: "请选择办学性质"
          }],
          area1: [{
            required: true,
            message: "请选择所属区域"
          }],
          address: [{
            required: true,
            message: "请输入详细地址"
          }],
          longitudeAndLatitude1: [{
            required: true,
            message: "请输入经纬度"
          }],
        },
      };
    },
    computed: {
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
    },
    created() {
      this.getList()
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.getTableHeight(330)
      })
        this.getAreaFourLevel()
    },
    methods: {
      getTableHeight(val) {
        this.tableHeight = this.$util.getHeight(val)
      },
       // 重置密码-点击
      reset(val) {
        this.resetForm = {
          password: "",
          id: "",
        };
        if (this.$refs.resetForm) {
          this.$refs.resetForm.resetFields();
        }
        this.resetDialog = true;
        this.resetForm.id = val;
      },
       // 重置密码-接口
      resetSubmit() {
        this.$refs.resetForm.validate((result) => {
          if (result) {
            this.$request(resetUserPsw, {
              password: this.$util.Encrypt(this.resetForm.password),
              id: this.resetForm.id
            }).then((res) => {
              if (res.code === "200") {
                if (res.data == "1") {
                  this.$router.push('/login')
                  this.$util.message("err", "密码已重置  请输入新密码");
                  return
                }
                this.$util.message("suc", "密码已重置");
                this.getList();
                this.resetDialog = false;
              }
            });
          }
        });
      },
      getList(val) {
        this.$request(getSchoolList(this.current,this.size), this.searchForm).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            if(data.records&&data.records.length>0){
              data.records.forEach((item)=>{
                item.schoolLevel = item.schoolLevel.split(',')
              })
            }
            this.tableData = data.records;
            this.total = Number(data.total);
          }
        });
      },
        // 获取地区
      getAreaFourLevel() {
        this.$request(areaLevel).then((res) => {
          if (res.code === 0) {
            let list = res.data
            this.areaLevelList = list
          }
        });
      },
      
      //启用禁用 
       // 推荐、上架、删除
      changeState(val, bool) {
        let text;
        if (!bool) {
          text = "确定删除？";
        } else if (bool == 1) {
          text = val.status == 1 ? "确认启用？" : "确认禁用？";
        } 
        val.bool = bool;
        this.$util.isSwal(text, this.changeStateSubmit, val);
      },
      changeStateSubmit(val) {
        let url = !val.bool ?this.$request(deleteSchool,{schoolId:val.schoolId}) : this.$request(schoolStatus,{schoolId:val.schoolId, status:val.status == "0" ? "1" : "0"});
        url.then((res) => {
          if (res.code === 0) {
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      //   新增\编辑-弹框
      add(val) {
        this.edit = val;
        this.addDialog = true;
        this.addForm = {
          schoolName: "",
          eduCode:"",
          province:"",
          city:"",
          district:"",
          area: [],
          address: "",
          isPublic: "",
          schoolLevel:[],
          lng: "",
          lat: "",
          scale:"",
          longitudeAndLatitude: ""
        }
        if (this.$refs.clearCheckedNodes) {
          this.$refs.clearCheckedNodes.$refs.panel.clearCheckedNodes();
          this.$refs.clearCheckedNodes.$refs.panel.activePath = [];
        }
        if (this.$refs.addForm) {
          this.$refs.addForm.resetFields();
        }
        if (!this.areaLevelList.length) {
          this.getAreaFourLevel()
        }
        if (this.edit) {
          this.getOrganizationInfo(val.schoolId);
        }
      },
      // 详情
      getOrganizationInfo(id) {
        this.$request(schoolInfo,{schoolId:id}).then((res) => {
          if (res.code == 0) {
            this.addForm = res.data
            this.addForm.longitudeAndLatitude = this.addForm.lat + "," + this.addForm.lng
            this.addForm.area = (this.addForm.province + ","+this.addForm.city+ ","+this.addForm.district).split(',')
            this.addForm.schoolLevel = this.addForm.schoolLevel.split(',')
            console.log(this.addForm.schoolLevel,"this.addForm.schoolLevel")
          }
        });
      },
      //   新增编辑-上传接口
      addSubmit() {
        let url = this.edit ? schoolUpdate : schoolSave;
        // console.log(this.addForm,this.addForm.schoolLevel,"this.addForm.this.addForm.")
        this.$refs.addForm.validate((result) => {
          if (result) {
            let arr = this.addForm.longitudeAndLatitude.split(",")
            this.addForm.lng = arr[1]
            this.addForm.lat = arr[0]
            this.addForm.province = this.addForm.area[0]
            this.addForm.city = this.addForm.area[1]
            this.addForm.district = this.addForm.area[2]
            this.addForm.schoolLevel = this.addForm.schoolLevel.join()
            this.$request(url, this.addForm).then((res) => {
              if (res.code == 0) {
                if (res.data) {
                  this.addDialog = false;
                  this.$util.message("suc", "操作成功");
                  this.getList();
                } else {
                  this.$util.message("err", "经纬度不正确，请重新输入");
                }
              }
            });
          }
        });
      },
      Level(val) {
        switch (val) {
          case "1":
            return "小学";
          case "2":
            return "初中";
          case "3":
            return "高中";
          case "4":
            return "大学";
        }
      },
         showState(val) {
        switch (val) {
          case 0:
            return "启用";
          case 1:
            return "禁用";
        }
      },
      stateClass(val) {
        switch (val) {
          case 0:
            return "green_dot";
          case 1:
            return "grey_dot";
        }
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
    },
  };
</script>

<style lang="less" scoped>
  .navigation {
    .w_48_l {
      width: 60%;
      display: inline-block;
    }

    .w_48 {
      width: 40%;
      display: inline-block;

      /deep/.el-form-item__content {
        margin-left: 12px !important;
      }

    }
  }
</style>